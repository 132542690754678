<template>
  <button :class="['btn', className, 'btn-' + size]">
    <slot />
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String,
    className: String,
    size: {
      type: String,
      default: "lg",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.btn {
  font-weight: 700;
  text-align: center;
  border-radius: 4px;
  transition: color 0.2s ease, background-color 0.2s ease,
    border-color 0.2s ease;

  &-sm {
    font-size: 14px;
    line-height: 24px;
    padding: 4px 8px;
  }

  .login-page & {
    width: 100%;
    display: block;
    margin-top: 40px;
  }

  &-lg {
    padding: 8px 16px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 19px;
    }

    @media screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 32px;
      height: 48px;
      padding: 8px 16px;
    }
  }

  &._filled {
    border: 1px solid transparent;
    cursor: pointer;
    color: #fff;

    &:hover {
      background-color: #fff;
    }
  }

  &._outlined {
    background: #fff;
    color: $color-blue-light;
    border: 1px solid $color-blue-light;

    &:hover {
      border-color: transparent;
      background: $color-blue-light;
      color: #fff;
    }
  }

  &.crop-btn {
    background: #000;
    color: #fff;
    border: 1px solid transparent;

    &:hover {
      background: #fff;
      color: #000;
      border-color: #000;
    }
  }

  &.btn-primary {
    background: $color-blue-light;

    &:hover {
      border-color: $color-blue-light;
      color: $color-blue-light;
    }
  }

  &.btn-secondary {
    background: $bg-secondary-color;

    &:hover {
      border-color: $bg-secondary-color;
      color: $bg-secondary-color;
    }
  }

  &[disabled] {
    cursor: not-allowed !important;
    opacity: 0.7 !important;
  }
}
</style>

<template>
  <div :class="['logo', modifier]">
    <span class="logo__name"> <strong>Admins</strong>Panel </span>
    <div class="logo__cubes">
      <span class="cube"></span>
      <span class="cube"></span>
      <span class="cube"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    modifier: String,
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.logo {
  display: flex;
  align-items: center;

  &.login-logo .logo__name {
    font-size: 26px;
    line-height: 35px;
  }

  &__name {
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      display: none;
    }

    @media screen and (min-width: 768px) {
      color: $color-base;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    &--inner {
      @media screen and (max-width: 767px) {
        display: none;
      }

      @media screen and (min-width: 768px) {
        display: block;
      }
    }
  }

  &__cubes {
    width: 24px;
    height: 26px;
    margin-left: 10px;
    .cube {
      display: block;
      position: absolute;
      &:first-child {
        width: 14px;
        height: 14px;
        background: #3a58ff;
        right: 0;
        top: 0;
        z-index: 1;
      }
      &:nth-child(2) {
        width: 10px;
        height: 10px;
        left: 0;
        top: 10px;
        background: #78a6ff;
        z-index: 2;
      }
      &:last-child {
        width: 8px;
        height: 8px;
        background: #78dfff;
        bottom: 0;
        left: 50%;
        margin-left: -4px;
        z-index: 3;
      }
    }
  }

  &.login-logo .logo__cubes {
    width: 33px;
    height: 36px;

    .cube {
      &:first-child {
        width: 20px;
        height: 20px;
      }
      &:nth-child(2) {
        width: 15px;
        height: 15px;
      }
      &:last-child {
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>
